<template>
  <section
    id="financial-growth"
    class="col-16 align-center d-flex flex-column section-spacing"
  >
    <section-header :title="$tc('financialGrowth.title')" />
    <p class="col-md-8 my-12">
      {{ $tc("financialGrowth.details") }}
    </p>
  </section>
</template>

<script>
import SectionHeader from "@/components/SectionHeader.vue";
export default {
  components: { SectionHeader },
  name: "FinancialGrowth",
};
</script>

<style lang="scss" scoped>
.cormorant-garamond {
  font-family: "Cormorant Garamond";
}

#financial-growth {
  .banner {
    height: 600px;
    position: relative;
    overflow: hidden;
    z-index: 1;
    width: max-content;
    h1 {
      font-size: 180px;
      line-height: 200px;
      span {
        color: var(--red);
      }
    }
    img {
      position: absolute;
      top: -100px;
      z-index: -1;
      transform-origin: center;
      left: 0px;
    }
  }
}

@media screen and (max-width: 1263px) {
  #financial-growth {
    .banner {
      height: 300px;
      h1 {
        font-size: 90px;
        line-height: 100px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  #financial-growth {
    .banner {
      height: 150px;
      h1 {
        font-size: 45px;
        line-height: 50px;
      }
    }
  }
}
</style>
