<template>
  <div id="invest" class="section-spacing">
    <section-header :title="$tc('invest.title')" />
    <div>
      <p class="section-spacing">
        {{ $tc("invest.intro") }}
      </p>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import SectionHeader from "@/components/SectionHeader.vue";
export default Vue.extend({
  name: "Invest",

  components: {
    SectionHeader,
  },

  data() {
    return {};
  },
});
</script>

<style lang="scss">
#invest {
  margin-left: auto;
  margin-right: auto;
  max-width: 80%;
}
</style>
