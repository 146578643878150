<template>
  <div class="the-team-wrapper" id="theTeam">
    <section-header :title="$tc('theTeam.title')" />
    <section id="story" class="section-spacing">
      <div class="d-flex row d-flex align-center my-12">
        <div
          class="align-center ma-auto flex-wrap justify-space-around"
          id="person"
        >
          <img
            class="d-block mx-auto box-shadow-top-right"
            width="252"
            height="334"
            src="@/assets/images/Javier.jpg"
            alt="Javier La Roche, smiling along with a young boy"
          />
          <div class="ma-8">
            <h2>Javier La Roche</h2>
            <span>{{ $tc("theTeam.javier.title") }}</span>
          </div>
        </div>
        <p class="ma-8 col-sm-8 col-lg-6">
          {{ $tc("theTeam.javier.story1") }}
        </p>
      </div>
      <div
        class="
          row
          d-flex
          flex-row-reverse
          align-center
          justify-space-around
          my-12
        "
      >
        <div class="col-sm-8 col-lg-4 d-flex justify-center">
          <img
            width="390"
            height="530"
            src="@/assets/images/Boywred.jpg"
            alt="Young boy playing and smiling"
          />
        </div>
        <p class="col-sm-8 col-lg-6 ma-8">
          {{ $tc("theTeam.javier.story2") }}
        </p>
      </div>
      <div class="row d-flex flex-row align-center justify-space-around my-12">
        <div class="col-sm-8 col-lg-4 d-flex justify-center">
          <img
            width="535"
            height="434"
            src="@/assets/images/groupwred.jpg"
            alt="Classroom of children of various ages holding school papers"
          />
        </div>
        <p class="col-sm-8 col-lg-6 ma-8">
          {{ $tc("theTeam.javier.story3") }}
        </p>
      </div>
    </section>
    <div
      class="
        width-restricted
        mx-auto
        d-flex
        flex-row
        col-lg-16
        flex-wrap
        section-spacing
      "
    >
      <p class="mb-2">{{ $tc("theTeam.joinUs.intro") }}</p>
    </div>
    <section id="join" class="d-flex align-center justify-center flex-wrap">
      <div class="d-flex align-center col-lg-6">
        <ul class="mx-sm-4 mx-md-8 mx-lg-16">
          <li>{{ $tc("theTeam.joinUs.coachBasketball") }}</li>
          <li>{{ $tc("theTeam.joinUs.manageSocialMedia") }}</li>
          <li>{{ $tc("theTeam.joinUs.manageRealEstate") }}</li>
          <li>{{ $tc("theTeam.joinUs.teach") }}</li>
          <li>{{ $tc("theTeam.joinUs.manageCrypto") }}</li>
          <li>{{ $tc("theTeam.joinUs.sponsorUs") }}</li>
          <li>{{ $tc("theTeam.joinUs.sponsorYou") }}</li>
        </ul>
      </div>
    </section>
    <div
      class="width-restricted mx-auto my-2 d-flex flex-row col-lg-16 flex-wrap"
    >
      <p>
        {{ $tc("theTeam.joinUs.outro") }}
      </p>
    </div>
  </div>
</template>

<script>
import SectionHeader from "@/components/SectionHeader.vue";
export default {
  components: { SectionHeader },
  name: "TheTeam",
};
</script>

<style lang="scss" scoped>
.the-team-wrapper {
  #person {
    h2 {
      font-family: monospace;
      font-weight: bold;
      font-style: italic;
      font-size: 48px;
    }

    span {
      font-family: monospace;
      font-size: 36px;
    }
  }

  #story {
    .box-shadow-top-right {
      box-shadow: 40px -40px#8C3535;
    }
  }

  #join {
    position: relative;
    overflow: hidden;
    z-index: 1;
    background-image: url("~@/assets/images/jump_bwe.jpg");
    background-size: cover;
    background-position: center;
    background-color: rgba(0, 0, 0, 0.5);
    background-blend-mode: darken;
    img {
      position: absolute;
      z-index: -1;
      min-height: 100%;
      min-width: 100%;
      filter: brightness(50%);
    }
    li {
      margin: 50px auto;
    }

    .v-divider {
      border: var(--red) solid 3px;
      height: 700px;
    }
  }
}

.width-restricted {
  max-width: 80%;
}

@media screen and (max-width: 1263px) {
  .the-team-wrapper {
    #person {
      h2 {
        font-size: 24px;
      }
      span {
        font-size: 18px;
      }
    }
    #join {
      .v-divider {
        min-width: 80vw;
        height: 0px;
      }
    }
  }
}
</style>
