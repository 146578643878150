










import Vue from "vue";
import Navbar from "@/components/Navbar.vue";
import NABFooter from "@/components/Footer/Footer.vue";

export default Vue.extend({
  name: "App",

  components: {
    Navbar,
    NABFooter,
  },

  data: () => ({
    //
  }),
});
