<template>
  <section id="wip" class="d-flex flex-column justify-end section-spacing">
    <img
      src="@/assets/images/WorkInProgress.jpg"
      alt="A store window with the words 'Work in progress' painted along the top"
    />
    <div style="position: relative">
      <v-container class="col-16 d-flex flex-column align-center">
        <div class="message col-5 col-sm-8">
          <p>{{ $tc("contactUs.workInProgress") }}</p>
          <p>{{ $tc("contactUs.futureAdvancements") }}</p>
        </div>
      </v-container>
      <v-row class="d-flex justify-space-between col-8 mx-auto">
        <v-divider vertical />
        <h1 class="col-lg-4 col-md-8">{{ $tc("contactUs.title") }}</h1>
        <div class="col-3 details d-flex flex-column justify-center">
          <div>
            <v-icon>mdi-email-open-outline</v-icon>
            <span>javixapo@hotmail.com</span>
          </div>
          <div>
            <v-icon>mdi-send</v-icon>
            <span>+34 678 917 011</span>
          </div>
          <div>
            <v-icon>mdi-whatsapp</v-icon>
            <span>+34 678 917 011</span>
          </div>
        </div>
      </v-row>
    </div>
  </section>
</template>

<script>
export default {
  name: "ContactUs",
};
</script>

<style lang="scss" scoped>
@import "~@openfonts/staatliches_latin/index.css";
@import "~@openfonts/space-mono_all/index.css";

#wip {
  .v-divider {
    height: 650px;
    border: var(--red) solid 3px;
    position: absolute;
    bottom: 0px;

    top: -10px;
  }
  position: relative;
  overflow: hidden;
  z-index: 1;
  .message {
    font-family: space mono;
    p {
      margin: 64px auto;
    }
  }
  img {
    top: 0px;
    position: absolute;
    width: 100%;
    z-index: -1;
  }
  h1 {
    font-size: 64px;
    font-family: Staatliches;
  }

  .details {
    font-size: 24px;
    font-family: Roboto;
    i {
      color: white;
      margin: 8px;
    }
    div {
      display: flex;
      align-items: center;
    }
    span {
      display: block;
      white-space: nowrap;
    }
  }
}
@media screen and (max-width: 1263px) {
  #wip {
    img {
      visibility: hidden;
    }
    .v-divider {
      height: 325px;
    }
    .message {
      p {
        margin: 0px;
      }
    }
    h1 {
      font-size: 32px;
    }

    .details {
      font-size: 12px;
    }
  }
}
</style>
