<template>
  <div class="app-container">
    <Landing />
    <Vision />
    <ActionPlan />
    <TheTeam />
    <FinancialGrowth />
    <Donate />
    <Invest />
    <ContactUs />
  </div>
</template>

<script>
import Vue from "vue";

import Landing from "./Sections/Landing.vue";
import Vision from "./Sections/Vision.vue";
import ActionPlan from "./Sections/ActionPlan.vue";
import FinancialGrowth from "./Sections/FinancialGrowth.vue";
import TheTeam from "./Sections/TheTeam.vue";
import ContactUs from "./Sections/ContactUs.vue";
import Donate from "./Sections/Donate.vue";
import Invest from "./Sections/Invest.vue";

export default Vue.extend({
  name: "Home",

  components: {
    Landing,
    Vision,
    ActionPlan,
    FinancialGrowth,
    Donate,
    Invest,
    TheTeam,
    ContactUs,
  },

  data() {
    return {};
  },
});
</script>

<style lang="scss">
@import "~@openfonts/space-mono_all/index.css";
@import "~@openfonts/spartan_latin/index.css";

.app-container {
  padding: 0px;
  max-width: 100%;
  background-color: black;
  color: white;
  --red: rgb(216, 81, 81);
  overflow: hidden;

  font-family: spartan;
  font-size: 36px;

  .section-spacing {
    margin-top: 200px;
  }
}

@media screen and (max-width: 1263px) {
  .app-container {
    font-size: 28px;
    .section-spacing {
      margin-top: 100px;
    }
  }
}

@media screen and (max-width: 600px) {
  .app-container {
    font-size: 16px;

    .section-spacing {
      margin-top: 50px;
    }
  }
}
</style>
