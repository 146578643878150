<template>
  <div>
    <section
      id="foreword"
      class="
        d-flex
        flex-row
        col-lg-16
        flex-wrap
        justify-space-around
        align-center
        section-spacing
      "
    >
      <div class="col-sm-16 col-lg-6">
        <p class="pa-2 align-center justify-center">
          {{ $tc("vision.foreword") }}
        </p>
      </div>
      <div class="col-sm-16 col-lg-3 justify-center d-flex">
        <img height="482" src="@/assets/images/3kidswred.jpg" />
      </div>
    </section>
    <div id="whatIsYounir" class="section-spacing">
      <v-parallax src="@/assets/images/kidsbw.jpg" height="1150">
        <section id="question" class="text-center">
          <v-container class="col-16">
            <h1 class="mx-auto mt-0 mb-16">
              {{ $tc("vision.whatIsYounir.whatIs") }}
              <span>{{ $tc("vision.whatIsYounir.title") }}</span>
              ?
            </h1>
            <div class="align-center justify-center">
              <p>{{ $tc("vision.whatIsYounir.foundation") }}</p>
              <p>{{ $tc("vision.whatIsYounir.location") }}</p>
              <p>{{ $tc("vision.whatIsYounir.income") }}</p>
            </div>
          </v-container>
        </section>
      </v-parallax>
    </div>
  </div>
</template>

<script>
export default {
  name: "Vision",
};
</script>

<style lang="scss" scoped>
@import "~@openfonts/staatliches_latin/index.css";
@import "~@openfonts/yellowtail_latin/index.css";
@import "~@openfonts/spartan_latin/index.css";

#foreword {
  font-family: Spartan;
  span {
    font-family: yellowtail;
    color: rgb(216, 81, 81);
    font-size: 60px;
  }
}

#question {
  h1 {
    font-family: Staatliches;
    font-size: 160px;
    line-height: 166px;
    span {
      color: var(--red);
    }
  }
}

@media screen and (max-width: 1263px) {
  #foreword {
    span {
      font-size: 50px;
    }
  }

  #question {
    h1 {
      font-size: 80px;
      line-height: 86px;

      span {
        color: var(--red);
      }
    }
  }
}
@media screen and (max-width: 600px) {
  #foreword {
    span {
      font-size: 36px;
    }
  }
}
</style>
