<template>
  <div id="home">
    <v-container class="mt-0 mb-auto" id="landing-content">
      <v-row align="start" class="text-center">
        <v-col cols="12">
          <h2>
            <span class="black--text">{{ $tc("home.welcome") }} </span>
          </h2>
          <h1>
            <span class="text-red">{{ $tc("home.title") }}</span>
          </h1>
        </v-col>
      </v-row>
      <v-divider class="col-3 pa-0 ma-auto" />
      <v-container>
        <v-row align="start" class="text-center">
          <v-col cols="12" class="reduce-gap-bottom">
            <h2>"{{ $tc("home.slogan") }}"</h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" offset="8" class="text-start reduce-gap-top">
            <h3>({{ $tc("home.toUnite") }})</h3>
          </v-col>
        </v-row>
      </v-container>

      <v-row>
        <div class="col-lg-4 col-sm-1 ml-3">
          <p class="cover d-block">
            <span class="d-block col-5 col-md-12">
              {{ $tc("home.freeActivities.title") }}:
            </span>
            <em class="cursive">
              {{ $tc("home.freeActivities.languageCourses") }}
            </em>
            <br />
            <em class="cursive">{{ $tc("home.freeActivities.basketball") }}</em>
            <em class="hidden-when-small">
              {{ $tc("home.freeActivities.and") }}
            </em>
            <br />
            <em class="cursive">
              {{ $tc("home.freeActivities.hotelManagement") }}
            </em>
          </p>
        </div>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Landing",
};
</script>

<style lang="scss" scoped>
@import "~@openfonts/staatliches_latin/index.css";
@import "~@openfonts/yellowtail_latin/index.css";
@import "~@openfonts/spartan_latin/index.css";

.reduce-gap-top {
  padding-top: 0px;
}

#home {
  position: relative;
  background-color: black;
  overflow: hidden;
  background-image: url("~@/assets/images/girl_bwFlipped.jpg");
  background-size: cover;
  background-position: 20% 45%;
  min-height: 100vh;
  max-width: 85vw;
  margin: 64px auto;
}

#landing-content {
  position: relative;
  z-index: 1;
  overflow: hidden;

  .v-divider {
    border: solid black;
    width: 500px;
  }
  h1 {
    color: rgb(216, 81, 81);
    font-family: Staatliches;
    font-size: 144px;
    font-size: 9rem;
    line-height: 1em;
    font-weight: 300;
  }

  h2 {
    color: rgb(0, 0, 0);
    font-family: yellowtail;
    font-size: 48px;
    line-height: 1em;
  }

  h3 {
    color: rgb(0, 0, 0);
    font-family: monospace;
    font-size: 30px;
    text-decoration: none;
    font-weight: 300;
  }
}

.cover {
  font-family: spartan;
  font-size: 48px;
  color: black;
  width: 385px;
  line-height: 53px;
  width: 100%;
  .cursive {
    line-height: initial;
    font-size: 72px;
    font-family: yellowtail;
    color: var(--red);
    display: inline-block;
  }
}
@media screen and (max-width: 1263px) {
  .reduce-gap-top {
    padding-top: 0px;
  }
  .reduce-gap-bottom {
    padding-bottom: 0px;
  }
  #home {
    max-width: 100vw;
  }
  #landing-content {
    .v-divider {
      width: 250px;
    }
    h1 {
      font-size: 4.5rem;
    }

    h2 {
      font-size: 28px;
    }

    h3 {
      font-size: 15px;
    }
  }

  .cover {
    font-size: 24px;
    line-height: 26.5px;
    .cursive {
      font-size: 36px;
    }
  }

  #home {
    img {
      max-width: 960px;
      top: 0%;
    }
  }
}

@media screen and (max-width: 600px) {
  .hidden-when-small {
    visibility: hidden;
  }

  #home {
    img {
      max-width: 960px;
      top: 0%;
    }
  }
}
</style>
