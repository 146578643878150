<template>
  <div>
    <section id="action-plan" class="section-spacing">
      <section-header :title="$tc('actionPlan.title')" />
      <v-container class="section-spacing">
        <h2>{{ $tc("actionPlan.goals.title") }}:</h2>
        <v-timeline class="my-12">
          <v-timeline-item large color="#d85151">
            <template v-slot:icon>
              <v-icon color="white">mdi-pen</v-icon>
            </template>
            {{ $tc("actionPlan.goals.setupFoundation") }}
          </v-timeline-item>
          <v-timeline-item large color="#d85151" class="text-right">
            <template v-slot:icon>
              <v-icon color="white">mdi-map-search</v-icon>
            </template>
            {{ $tc("actionPlan.goals.decideStart") }}
          </v-timeline-item>
          <v-timeline-item large color="#d85151">
            <template v-slot:icon>
              <v-icon color="white">mdi-home-group</v-icon>
            </template>
            {{ $tc("actionPlan.goals.manageProperties") }}
          </v-timeline-item>
          <v-timeline-item large color="#d85151" class="text-right">
            <template v-slot:icon>
              <v-icon color="white">mdi-account-group</v-icon>
            </template>
            {{ $tc("actionPlan.goals.recruit") }}
          </v-timeline-item>
          <v-timeline-item large color="#d85151">
            <template v-slot:icon>
              <v-icon color="white">mdi-translate</v-icon>
            </template>
            {{ $tc("actionPlan.goals.courses") }}
          </v-timeline-item>
          <v-timeline-item large color="#d85151" class="text-right">
            <template v-slot:icon>
              <v-icon color="white">mdi-bitcoin</v-icon>
            </template>
            {{ $tc("actionPlan.goals.crypto") }}
          </v-timeline-item>
        </v-timeline>
      </v-container>
    </section>
  </div>
</template>

<script>
import SectionHeader from "@/components/SectionHeader.vue";

export default {
  name: "ActionPlan",

  components: {
    SectionHeader,
  },
};
</script>

<style lang="scss" scoped>
@import "~@openfonts/staatliches_latin/index.css";

// #action-plan {

// }
@media screen and (max-width: 1263px) {
  #action-plan {
    margin: 50px auto;
  }
}
</style>
