







import Vue from "vue";

export default Vue.extend({
  name: "SectionHeader",

  props: {
    title: {
      type: String,
      required: true,
      default() {
        return "";
      },
    },
  },
});
