





















































interface MenuItem {
  href: string;
  title: string;
  icon: string;
}
import Vue from "vue";

export default Vue.extend({
  data() {
    return {
      drawer: false,
      selected: null,
      languages: [
        { text: "English", value: "en" },
        { text: "Deutsch", value: "de" },
        { text: "Español", value: "es" },
        { text: "Français", value: "fr" },
      ],
    };
  },
  computed: {
    menuItems(): Array<MenuItem> {
      return this.listItems();
    },
  },
  methods: {
    listItems(): Array<MenuItem> {
      return [
        {
          title: this.$tc("navbar.menu.home"),
          icon: "mdi-home",
          href: "#home",
        },
        {
          title: this.$tc("navbar.menu.actionPlan"),
          icon: "mdi-transit-connection-variant",
          href: "#action-plan",
        },
        {
          title: this.$tc("navbar.menu.whatIsYounir"),
          icon: "mdi-help",
          href: "#whatIsYounir",
        },
        {
          title: this.$tc("navbar.menu.theTeam"),
          icon: "mdi-account-group",
          href: "#theTeam",
        },
        {
          title: this.$tc("navbar.menu.airbnb"),
          icon: "mdi-bunk-bed",
          href: "#financial-growth",
        },
        {
          title: this.$tc("navbar.menu.donate"),
          icon: "mdi-gift",
          href: "#crypto",
        },
        {
          title: this.$tc("navbar.menu.invest"),
          icon: "mdi-cash-multiple",
          href: "#invest",
        },
        {
          title: this.$tc("navbar.menu.contact"),
          icon: "mdi-lan-connect",
          href: "#wip",
        },
      ];
    },
  },
});
