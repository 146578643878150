



























































import SectionHeader from "@/components/SectionHeader.vue";
import Vue from "vue";

interface CryptoWallet {
  title: string;
  address: string;
}

export default Vue.extend({
  components: { SectionHeader },
  computed: {
    wallets(): Array<CryptoWallet> {
      return this.cryptoWallets();
    },
  },

  methods: {
    cryptoWallets(): Array<CryptoWallet> {
      return [
        {
          title: "Litecoin",
          address: "",
        },
        {
          title: "Bitcoin",
          address: "",
        },
        {
          title: "Ethereum",
          address: "",
        },
        {
          title: "Polygon (MATIC or ERC20)",
          address: "",
        },
        {
          title: "Dogecoin",
          address: "",
        },
        {
          title: "Binance Smart Chain (BNB or BEP20)",
          address: "",
        },
        {
          title: "Bitcoin Cash",
          address: "",
        },
        { title: "Zcash", address: "" },
        { title: "Yourcoin", address: "(yet to be created)" },
      ];
    },
  },
});
