<template>
  <v-footer padless class="black white--text">
    <v-col class="text-center d-flex justify-center" cols="12">
      <span class="ma-auto">Proudly by</span>
      <a href="https://www.nibbleabit.com" target="#" class="nab-footer-logo">
        <v-img
          src="@/assets/nab/Nab-Logo.png"
          alt="Nibble A Bit Logo"
          contain
          height="60px"
          class="ma-auto"
        />
      </a>
      <span class="ma-auto">Copyright {{ new Date().getFullYear() }}</span>
    </v-col>
  </v-footer>
</template>

<script>
export default {
  // data: () => ({
  //   //
  // }),
};
</script>
<style>
.nab-footer-logo {
  max-width: 50%;
}
</style>
